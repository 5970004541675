<template>
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div v-if="isLoading" class="spinner-border text-primary mt-4 m-auto">Завантаження...</div>

            <template v-else>
                <div class="col-12 layout-spacing">
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="widget-heading">
                                <h5>Надходження коштів сьогодні</h5>
                            </div>

                            <div class="widget-content">
                                <ul class="list-group col-9">
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> Готівка: </span>
                                            <span class="ms-4"><span class="badge badge-primary">{{ $prettyAmount(data?.[currentDayTimestamp]?.cashAmount || 0) }}</span></span>
                                        </div>
                                    </li>
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> QR: </span>
                                            <span class="ms-4 text-right"><span class="badge badge-secondary">{{ $prettyAmount(data?.[currentDayTimestamp]?.qrAmount || 0) }}</span></span>
                                        </div>
                                    </li>
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> POS: </span>
                                            <span class="ms-4"><span class="badge badge-warning">{{ $prettyAmount(data?.[currentDayTimestamp]?.cardReplenishmentsPosAmount || 0) }}</span></span>
                                        </div>
                                    </li>
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> Всього: </span>
                                            <span class="ms-4">
                                                <span class="badge badge-danger">
                                                    {{ $prettyAmount((data?.[currentDayTimestamp]?.cashAmount || 0) + (data?.[currentDayTimestamp]?.qrAmount || 0) + (data?.[currentDayTimestamp]?.cardReplenishmentsPosAmount || 0)) }}
                                                </span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="widget-heading">
                                <h5>Надходження коштів вчора</h5>
                            </div>

                            <div class="widget-content">
                                <ul class="list-group col-9">
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> Готівка: </span>
                                            <span class="ms-4"><span class="badge badge-primary">{{ $prettyAmount(data?.[yesterdayDayTimestamp]?.cashAmount || 0) }}</span></span>
                                        </div>
                                    </li>
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> QR: </span>
                                            <span class="ms-4 text-right"><span class="badge badge-secondary">{{ $prettyAmount(data?.[yesterdayDayTimestamp]?.qrAmount || 0) }}</span></span>
                                        </div>
                                    </li>
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> POS: </span>
                                            <span class="ms-4"><span class="badge badge-warning">{{ $prettyAmount(data?.[yesterdayDayTimestamp]?.cardReplenishmentsPosAmount || 0) }}</span></span>
                                        </div>
                                    </li>
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> Всього: </span>
                                            <span class="ms-4">
                                                <span class="badge badge-danger">
                                                    {{ $prettyAmount((data?.[yesterdayDayTimestamp]?.cashAmount || 0) + (data?.[yesterdayDayTimestamp]?.qrAmount || 0) + (data?.[yesterdayDayTimestamp]?.cardReplenishmentsPosAmount || 0)) }}
                                                </span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="widget-heading">
                                <h5>За останні 7 днів</h5>
                            </div>

                            <div class="widget-content">
                                <ul class="list-group col-9">
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> Готівка: </span>
                                            <span class="ms-4"><span class="badge badge-primary">{{ $prettyAmount(last7daysData?.cashAmount || 0) }}</span></span>
                                        </div>
                                    </li>
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> QR: </span>
                                            <span class="ms-4 text-right"><span class="badge badge-secondary">{{ $prettyAmount(last7daysData?.qrAmount || 0) }}</span></span>
                                        </div>
                                    </li>
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> POS: </span>
                                            <span class="ms-4"><span class="badge badge-warning">{{ $prettyAmount(last7daysData?.cardReplenishmentsPosAmount || 0) }}</span></span>
                                        </div>
                                    </li>
                                    <li class="list-group-item list-group-item-action">
                                        <div class="d-inline-flex">
                                            <span class="ms-2"> Всього: </span>
                                            <span class="ms-4">
                                                <span class="badge badge-danger">
                                                    {{ $prettyAmount((last7daysData?.cashAmount || 0) + (last7daysData?.qrAmount || 0) + (last7daysData?.cardReplenishmentsPosAmount || 0)) }}
                                                </span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="widget widget-sales-category mt-4 h-75">
                        <div class="widget-heading">
                            <h5>Надходження коштів</h5>
                        </div>
                        <div class="row my-2 mx-4">
                            <div class="col">
                                <h6>Інтервал дат:</h6>
                            </div>
                            <div class="col">
                                <flat-pickr v-model="datesCash" :config="flatPickrConfig" @on-change="emitOnChangeCash" class="form-control flatpickr active"></flat-pickr>
                            </div>
                        </div>
                        <div v-if="chartCashIsActive" class="widget-content">
                            <apexchart height="460" type="area" :options="optionsCash" :series="seriesCash"></apexchart>
                        </div>
                    </div>
                </div>

                <div class="col-12 layout-spacing mb-2">
                    <div class="widget widget-sales-category mt-t">
                        <div class="widget-heading">
                            <h5>Поповнення карт лояльності</h5>
                        </div>
                        <div class="row my-2 mx-4">
                            <div class="col">
                                <h6>Інтервал дат:</h6>
                            </div>
                            <div class="col">
                                <flat-pickr v-model="datesCard" :config="flatPickrConfig" @on-change="emitOnChangeCard" class="form-control flatpickr active"></flat-pickr>
                            </div>
                        </div>
                        <div v-if="chartCardIsActive" class="widget-content">
                            <apexchart height="460" type="line" :options="optionsCards" :series="seriesCards"></apexchart>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { useMeta } from '@/composables/use-meta';
    import dayjs from 'dayjs';
    import apexchart from 'vue3-apexcharts';
    import '@/assets/sass/widgets/widgets.scss';

    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import '@/assets/sass/forms/custom-flatpickr.css';
    import { Ukrainian } from "flatpickr/dist/l10n/uk.js"
    flatpickr.localize(Ukrainian);

    useMeta({ title: 'Dashboard' });
</script>

<script>
    export default {
        name: 'Dashboard',

        components: {},

        data: () => ({
            isLoading: false,

            data: {},
            chartDataCash: {},
            chartDataCard: {},

            currentDayTimestamp: dayjs(dayjs().format('YYYY-MM-DD')).unix(),
            yesterdayDayTimestamp: dayjs(dayjs().add(-1, 'days').format('YYYY-MM-DD')).unix(),
            last7daysData: {
                cashAmount: 0,
                qrAmount: 0,
                cardReplenishmentsPosAmount: 0
            },

            categoriesCash: [],
            categoriesCard: [],
            chartCashIsActive: false,
            chartCardIsActive: false,

            datesCash: null,
            datesCard: null,
            flatPickrConfig: {
                mode: "range",
                dateFormat: "d-m-Y",
                defaultDate: [new Date().fp_incr(-31), "today"],
                maxDate: "today"
            }
        }),

        computed: {
            optionsCash() {
                const that = this;

                return {
                    chart: { toolbar: { show: false } },
                    dataLabels: { enabled: false },
                    stroke: { curve: 'smooth' },
                    xaxis: {
                        categories: that.categoriesCash,
                        labels: {
                            formatter: function (value) {
                                if (!value) {
                                    return;
                                }

                                return dayjs(value * 1000).format('DD-MM');
                            },
                        },
                    },
                    yaxis: {
                        title: { text: 'тис. грн.', style: { fontWeight: 'bold' } },
                        labels: {
                            formatter: (val) => that.$prettyAmount((val * 100) / 1000, false, false),
                        },
                    },
                    tooltip: {
                        x: {
                            formatter: function (index) {
                                const timestamp = that.categoriesCash[index - 1];
                                return dayjs(timestamp * 1000).format('DD-MM-YYYY');
                            },
                        },
                        y: {
                            formatter: function (val) {
                                return that.$prettyAmount(val * 100);
                            },
                        },
                    },
                };
            },

            seriesCash() {
                return [
                    {
                        name: 'Готівка',
                        data: Object.values(this.chartDataCash)
                            .sort((a, b) => a.timestamp - b.timestamp)
                            .map((el) => el.cashAmount / 100),
                    },

                    {
                        name: 'QR',
                        data: Object.values(this.chartDataCash)
                            .sort((a, b) => a.timestamp - b.timestamp)
                            .map((el) => el.qrAmount / 100),
                    },
                    {
                        name: 'POS',
                        data: Object.values(this.chartDataCash)
                            .sort((a, b) => a.timestamp - b.timestamp)
                            .map((el) => el.cardReplenishmentsPosAmount / 100),
                    },

                    {
                        name: 'Всього',
                        data: Object.values(this.chartDataCash)
                            .sort((a, b) => a.timestamp - b.timestamp)
                            .map((el) => el.cardReplenishmentsPosAmount / 100 + el.qrAmount / 100 + el.cashAmount / 100),
                    },
                ];
            },

            optionsCards() {
                const that = this;

                return {
                    colors : ['#00e396', '#008ffb', '#ffb01a'],
                    chart: { toolbar: { show: false } },
                    dataLabels: { enabled: false },
                    stroke: { width: [0, 4] },
                    xaxis: {
                        categories: that.categoriesCard,
                        labels: {
                            formatter: function (value) {
                                if (!value) {
                                    return;
                                }

                                return dayjs(value * 1000).format('DD-MM');
                            },
                        },
                    },
                    yaxis: [
                        {
                            showAlways: true,
                            seriesName: 'Готівка',
                            title: { text: 'тис. грн.', style: { fontWeight: 'bold' } },
                            labels: {
                                formatter: (val) => {
                                    return parseFloat(val / 1000).toFixed(1);
                                },
                            },
                        },
                        {
                            seriesName: 'POS',
                            show: false
                        },
                        {
                            seriesName: 'Кількість',
                            opposite: true,
                            title: { text: 'Кількість' },
                            labels: {
                                formatter: (val) => {
                                    return parseInt(val, 10);
                                },
                            },
                        },
                    ],
                    grid: { borderColor: '#191e3a' },

                    tooltip: {
                        theme: 'dark',
                        x: {
                            formatter: function (index) {
                                const timestamp = that.categoriesCard[index - 1];
                                return dayjs(timestamp * 1000).format('DD-MM-YYYY');
                            },
                        },
                        y: {
                            formatter: function (val, { seriesIndex }) {
                                if (seriesIndex == 0 || seriesIndex == 1) {
                                    return that.$prettyAmount(val * 100);
                                }

                                return val;
                            },
                        },
                    },
                };
            },

            seriesCards() {
                return [
                    {
                        name: 'POS',
                        type: 'column',
                        data: Object.values(this.chartDataCard)
                            .sort((a, b) => a.timestamp - b.timestamp)
                            .map((el) => el.cardReplenishmentsPosAmount / 100),
                    },
                    {
                        name: 'Готівка',
                        type: 'column',
                        data: Object.values(this.chartDataCard)
                            .sort((a, b) => a.timestamp - b.timestamp)
                            .map((el) => el.cardReplenishmentsCashAmount / 100),
                    },
                    {
                        name: 'Кількість',
                        type: 'line',
                        data: Object.values(this.chartDataCard)
                            .sort((a, b) => a.timestamp - b.timestamp)
                            .map((el) => el.cardReplenishmentsCount),
                    },
                ];
            },
        },

        async mounted() {
            await this.loadInfo();
        },

        methods: {
            async loadInfo(startDate, endDate) {
                this.isLoading = true;

                let url = `dashboard?startDate=${startDate || dayjs().add(-31, 'days').format('YYYY-MM-DD')}&endDate=${endDate || dayjs().format('YYYY-MM-DD')}`;

                const response = await this.$json.get(url);

                console.log('Load dashboard', response);

                if (!response || response?.statusCode == 401) {
                    console.log('UNAUTHORIZED');
                    await getWhoAmI();
                    this.$router.push('/');
                    return;
                }

                const data = response.json.periodStats;

                for (const item of data) {
                    this.data[item.timestamp] = item;
                }

                for (let i=0; i < 7; i++) {
                    const timestamp = dayjs(dayjs().add(-i, 'days').format('YYYY-MM-DD')).unix();
                    this.last7daysData.cashAmount += this.data?.[timestamp]?.cashAmount;
                    this.last7daysData.qrAmount += this.data?.[timestamp]?.qrAmount;
                    this.last7daysData.cardReplenishmentsPosAmount += this.data?.[timestamp]?.cardReplenishmentsPosAmount;
                }

                await this.loadCashInfo();
                await this.loadCardInfo();

                this.isLoading = false;
            },

            async loadCashInfo(startDate, endDate) {
                this.chartCashIsActive = false;

                let url = `dashboard?startDate=${startDate || dayjs().add(-31, 'days').format('YYYY-MM-DD')}&endDate=${endDate || dayjs().format('YYYY-MM-DD')}`;
                const response = await this.$json.get(url);

                console.log('loadCashInfo', response);

                if (!response || response?.statusCode == 401) {
                    console.log('UNAUTHORIZED');
                    await getWhoAmI();
                    this.$router.push('/');
                    return;
                }

                this.chartDataCash = {};
                const data = response.json.periodStats;

                for (const item of data) {
                    this.chartDataCash[item.timestamp] = item;
                }

                this.categoriesCash = Object.keys(this.chartDataCash);
                this.chartCashIsActive = true;
            },
            async loadCardInfo(startDate, endDate) {
                this.chartCardIsActive = false;

                let url = `dashboard?startDate=${startDate || dayjs().add(-31, 'days').format('YYYY-MM-DD')}&endDate=${endDate || dayjs().format('YYYY-MM-DD')}`;
                const response = await this.$json.get(url);

                console.log('loadCardInfo', response);

                if (!response || response?.statusCode == 401) {
                    console.log('UNAUTHORIZED');
                    await getWhoAmI();
                    this.$router.push('/');
                    return;
                }

                this.chartDataCard = {};
                const data = response.json.periodStats;

                for (const item of data) {
                    this.chartDataCard[item.timestamp] = item;
                }

                this.categoriesCard = Object.keys(this.chartDataCard);
                this.chartCardIsActive = true;
            },
            emitOnChangeCash(selectedDates) {
                if (Array.isArray(selectedDates) && selectedDates.length == 2) {
                    this.loadCashInfo(dayjs(selectedDates[0]).format('YYYY-MM-DD'), dayjs(selectedDates[1]).format('YYYY-MM-DD'));
                }
            },
            emitOnChangeCard(selectedDates) {
                if (Array.isArray(selectedDates) && selectedDates.length == 2) {
                    this.loadCardInfo(dayjs(selectedDates[0]).format('YYYY-MM-DD'), dayjs(selectedDates[1]).format('YYYY-MM-DD'));
                }
            }
        },
    };
</script>
