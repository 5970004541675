/**
 * Implements JSON request with HTTP header authorization and JSON parsing.
 * Requests are done via window.fetch.
 * No errors reported and a javascript object with `json` (optional) and `statusCode` is always returned from the method.
 *
 * Use `if (response.json?.success)` to check for success.
 *
 * Install in Vue:
 *
 * main.js:
 *
 * import jsonClient from './jsonClient';
 * app.use(jsonClient, { localStorageKeyName: 'something' })
 *
 * `localStorageKeyName` points to a `window.localStorage` key containing current valid JWT token. This token is sent
 * in Authorization header with all requests.
 *
 * Methods:
 *
 * $json.get()
 * $json.put()
 * $json.patch()
 * $json.post()
 * $json.delete()
 *
 * Call signature:
 * $json.METHOD({ url, data });
 *
 * For the GET request you can also directly specify URL as the first and only argument:
 * $json.get(url);
 *
 * (Both call conventions are supported for .get())
 *
 * Example:
 * const response = await this.$json.get('/api/whoami');
 * if (response.json?.success) {
 *   alert("You are " + response.json.username);
 * } else {
 *   alert("error: " + response.statusCode);
 * }
 */

// key name for JWT token in window.localStorage
let localStorageKeyName = process.env.VUE_APP_LOCALSTORAGE_JWT;
const BASE_URL = process.env.VUE_APP_API_URL_PREFIX;

/**
 * Pack hash `data` into query arguments of the given `url`.
 *
 * @param {String} urlString source URL
 * @param {Object} data hash to be packed into query arguments
 * @returns {String} url with query arguments from `data`
 */
function packDataInQuery(urlString, data) {
  const u = new URL(urlString, document.location.href);
  for (const [ key, value ] of Object.entries(data)) {
    u.searchParams.set(key, value);
  }
  return u.toString();
}

/**
 * Actually perform the JSON request.
 *
 * @param {String} options
 * @param {String} options.url URL to request
 * @param {String} [options.method="POST"] HTTP method to use
 * @param {Object} [data] data to submit (serialized in query for GET requests and in POST body for others)
 * @returns {Object} json response or `{}` in case of failure.  No errors returned.
 * @private
 */
async function jsonClient({ url, method = 'POST', data }) {
  const methodUppercase = method.toUpperCase(); // apparently some http methods are case-sensitive for window.fetch()

  const options = {
    method: methodUppercase,
    headers: { },
    credentials: 'same-origin'
  };

  if (localStorageKeyName) {
    const jwt = localStorage.getItem(localStorageKeyName);

    if (jwt) {
      options.headers.Authorization = 'Bearer ' + jwt;
    }
  }

  let finalUrl = BASE_URL + url;

  if (data) {
    if (methodUppercase == 'GET') {
      finalUrl = packDataInQuery(url, data);
    } else {
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(data);
    }
  }

  let statusCode = null;
  let json = null;

  try {
    const response = await window.fetch(finalUrl, options);

    statusCode = response.status;
    json = await response.json();
  } catch (e) {
    if (e.message == 'Failed to fetch') {
      return { statusCode: 100 };
    }
  }

  return { statusCode, json };
}

const $json = {
  get: args => {
    if (typeof args == 'string') {
      return jsonClient({
        method: 'GET',
        url: args
      });
    }

    return jsonClient({ method: 'GET', ...args });
  },

  put: args => jsonClient({ method: 'PUT', ...args }),
  patch: args => jsonClient({ method: 'PATCH', ...args }),
  delete: args => jsonClient({ method: 'DELETE', ...args }),
  post: args => jsonClient({ method: 'POST', ...args })
};

export { $json };

export default {
  install(app, options = {}) {
    localStorageKeyName = options.localStorageKeyName || null;

    app.config.globalProperties.$json = $json;
  }
};
