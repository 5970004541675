import { ref, computed } from 'vue';
import { $json } from '@/jsonClient.js';

const isUserLoading = ref(true);
const isFetchError = ref(false);
const user = ref(null);

const isLoggedInUser = computed(() => Boolean(user.value));

async function getWhoAmI(skipLoader = false) {
    if (!skipLoader) {
        isUserLoading.value = true;
    }

    const userId = window.localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_USER);
    if (!userId) {
        user.value = null;

        if (!skipLoader) {
            isUserLoading.value = false;
        }
        return;
    }

    const response = await $json.get({
        url: `user/info?userId=${userId}`
    });

    if (!response.json?.success) {
        user.value = null;

        if (!skipLoader) {
            isUserLoading.value = false;
        }

        if (response.statusCode == 100) {
            isFetchError.value = true;
        }

        return;
    }

    isFetchError.value = false;

    // we are a logged in user
    if (response.json.email) {
        user.value = {
            email: response.json.email,
            firstName: response.json.firstName,
            lastName: response.json.lastName,
            type: response.json.userType
        };

    } else {
        user.value = null;
    }

    if (!skipLoader) {
        isUserLoading.value = false;
    }
}

// Fired by LoginPage once the full login API requests procedure is done
// and a valid JWT token has been supplied to us.
async function onLoginComplete(jwt, userId) {
    window.localStorage.setItem(process.env.VUE_APP_LOCALSTORAGE_JWT, jwt);
    window.localStorage.setItem(process.env.VUE_APP_LOCALSTORAGE_USER, userId);

    // getWhoAmI will now send the token to the server and the server will reply
    // with a valid user information.
    await getWhoAmI(true);

    if (isLoggedInUser.value) {
      window.location.replace('/');
    }
}

export {
    isUserLoading,
    user,
    isLoggedInUser,
    isFetchError,
    getWhoAmI,
    onLoginComplete
};