import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

export default {
    install: (app) => {
        app.config.globalProperties.$prettyAmount = function (amount, showCents = false, showCurrency = true) {
            const parsedAmount = parseInt(amount, 10) || 0;

            let formatter = new Intl.NumberFormat('uk-UA', {
                style: 'currency',
                currency: 'UAH',

                // These options are needed to round to whole numbers if that's what you want.
                minimumFractionDigits: showCents ? 2 : 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                maximumFractionDigits: showCents ? 2 : 0, // (causes 2500.99 to be printed as $2,501)
            });

            if (!showCurrency) {
                formatter = new Intl.NumberFormat('uk-UA', {
                    // These options are needed to round to whole numbers if that's what you want.
                    minimumFractionDigits: showCents ? 2 : 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                    maximumFractionDigits: showCents ? 2 : 0, // (causes 2500.99 to be printed as $2,501)
                });
            }

            return formatter.format(parsedAmount / 100);
        };

        // app.config.globalProperties.$formatPrice = function (value) {
        //     return value;
        // };

        app.config.globalProperties.$getFormattedDateTime = function (time, defaultValue = 'n/a') {
            const timestamp = new Date(time).getTime();
            if (timestamp === null || timestamp === undefined || isNaN(timestamp) || timestamp === '') {
                return defaultValue;
            }

            return dayjs(timestamp).utc('z').local().format('DD.MM.YYYY HH:mm:ss');
        };
    },
};
